
*{
    margin: 0px;
    padding: 0px;
    text-align: center;
}
.flex{
    height: 100vh;
    width: 100%;
    display: flex;
}
    .flex_column1, .flex_column2{
        position: relative;
        width: 50%;
        height: 110vh;
        background-color: rgb(69, 98, 124);
    }

        .flex_column1_video{
        position: relative;
        height: 100vh;
        width: 100%;
        object-fit: cover;
        -webkit-object-fit:cover;
        @media screen and (max-width: 800px) {
            height: 50vh;
        }
        }
        .flex_column1_content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: linear-gradient(to right, rgba(0,0,0,0.171), rgba(0,0,0,0.171));
        box-shadow: 0 5rem 4rem rgba(0, 0, 0, 0.65);
        background-color: rgba(0,0,0,0.322);
        }
        .flex_column1_content-text{
        padding: 20px;
        font-size:xx-large;
        color: rgb(255, 255, 255);
        font-weight: bolder;
        }
        .flex_column2_content{
        position: absolute;
        width: 600px;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: linear-gradient(to right, rgba(0,0,0,0.171), rgba(0,0,0,0.171));
        box-shadow: 0 5rem 4rem rgba(0, 0, 0, 0.65);
        background-color: rgba(0,0,0,0.322);
        }
        .flex_column2_content-text{
        padding: 20px;
        color: rgb(255, 255, 255);
        font-weight: bolder;
        }
.link_from_front_page{
    text-decoration: none;
    color: aliceblue;
    font-size:xx-large;
}
.link{
    text-align: center;
    color: black;
    text-decoration: none;
    margin-top: 15px;
    font-size: 20px;
    
}
.link:hover{
    color: lightgray;
}
header{
    border-bottom: 3px 
    double lightgray;
    /* background-color: rgb(230, 215, 215); */
}
h1{
    font-size: 42px;
    margin: 0px;
    text-align: center;
    /* padding: 20px 0px; */
}
ul{
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin: 20px;
    
}
li{
    font-size: 26px;
    color: grey;
    padding: 30px;
}
label{
    display: block;
    font-size: 30px;
}
input, select, input[type="text"], input[type="password"]{
    align-items: center;
    width: 400px;
    font-size: 15px;
    margin: 5px auto;
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;
    box-shadow: 0 1rem 2rem rgba(8, 7, 7, 0.287);
}
.video{
    height: 80vh;
    width: 100vh;
    margin-top:-12vh;
    
}
.front-page-video{
    height: 75vh;
    background-image: linear-gradient(to right, rgba(70, 27, 27, 0.452), rgba(194, 85, 85, 0.171));
    margin-bottom: 100px;
    padding-bottom: 10vh;    
}
.displayBack {
    margin-bottom:0px;
    margin-top:-10vh;
    padding-top:1vh;
    font-style:italic;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.171), rgba(149, 147, 147, 0.171));
}
.dashboard{
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.171), rgba(149, 147, 147, 0.171));
    margin: auto; 
    height: 100%;
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap;
    justify-content: center;
}
.dashboard-albums{
    margin: 30px;
    width: 30%;
    height: 60%;
    padding-top:5px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.171), rgba(149, 147, 147, 0.171));
    border-radius: 20px;
    box-shadow: 0 1rem 2rem rgba(8, 7, 7, 0.287);
}
.one-album{
    padding: 20px;
    display: flex;
    margin: auto;
    flex-direction: row; 
    flex-wrap: wrap;
    justify-content: center;
    width: 100vh;
    border-radius: 10%;
    background-image: linear-gradient(to right, rgba(100, 44, 44, 0.171), rgba(63, 27, 27, 0.171));
    box-shadow: 0 1rem 2rem rgba(8, 7, 7, 0.287);
}
.edit-album{
    padding: 20px;
    margin: auto;
    justify-content: center;
    width: 50%;
    border-radius: 10%;
    background-image: linear-gradient(to right, rgba(100, 44, 44, 0.171), rgba(63, 27, 27, 0.171));
    box-shadow: 0 1rem 2rem rgba(8, 7, 7, 0.287);
}
.one-album-pic{
    width: 40vh;
    height: 40vh;
    border-radius: 20px;
}
.edit-album-pic{
    width: 20vh;
    height: 20vh;
    border-radius: 20px;
}
.single-album-button{
    top: 110%;
    left: 32%;
    border: 1px solid rgb(233, 214, 214);
    color: black;
    text-decoration: none;
    font-weight:700;
    font-size: 20px;
    border-radius: 4px;
    padding: 5px;
    box-shadow: 0 3rem 4rem rgba(0, 0, 0, 0.287);
    margin: 2px 3px;
}
.album{
    width: 25%;
    margin: auto;
    text-align: center;
    background-color: aliceblue;
}

.UserWrapper {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.171), rgba(149, 147, 147, 0.171));
    padding-bottom: 8%;
}

